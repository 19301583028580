/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { head } from 'lodash-es'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import classnames from 'classnames'
import * as styles from './style.module.scss'
import Seo from '~components/globals/Seo'
import SubscriptionBanner from '~components/SubscriptionBanner'
import Container from '~components/globals/Container'
import PageHeader from '~components/globals/PageHeader'

export const Privacy = ({
  data: {
    allPrismicPage,
    prismicPrivacyContent: { data },
  },
  location,
}) => {
  const { content, headline } = data
  const { sharing_description, sharing_image, sharing_title } = allPrismicPage.edges[0].node.data

  return (
    <Container>
      <Seo
        title={sharing_title.text}
        description={sharing_description.text}
        url={location.href}
        image={sharing_image.url}
      />
      <Container extraClassName={styles.container}>
        <PageHeader
          bgColor="#fff"
          extraClassname={styles.pageHeader}
          textColor="#000"
          headline={headline.text}
        />
        <div data-html className={styles.content}>
          {parse(content.html)}
        </div>
      </Container>
      <SubscriptionBanner />
    </Container>
  )
}

export default Privacy

export const pageQuery = graphql`
  query {
    prismicPrivacyContent {
      data {
        headline {
          text
        }
        content {
          html
        }
      }
    }
    allPrismicPage(filter: {data: {slug: {eq: "privacy"}}}) {
      edges {
        node {
          data {
            sharing_description {
              text
            }
            sharing_title {
              text
            }
            sharing_image {
              url
            }
          }
        }
      }
    }
  }
`
Privacy.propTypes = {
  data: PropTypes.shape({
    prismicPrivacyContent: PropTypes.shape({
      data: PropTypes.shape({
        headline: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          }),
        ),
        content: PropTypes.arrayOf(
          PropTypes.shape({
            alt: PropTypes.string,
            text: PropTypes.string,
            type: PropTypes.string.isRequired,
            url: PropTypes.string,
          }),
        ),
      }),
    }),
    prismicPage: PropTypes.shape({
      data: PropTypes.shape({
        sharing_title: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_description: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_image: PropTypes.shape({ url: PropTypes.string }),
      }),
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}
